import {
  request
} from "../request";
import loaclstore from 'store-gem.js'
var token = "3cdedb64-d70b-4d94-bba9-d512fe17eb3b"; //正式服
// var token = '88a518b5-fc9e-45c8-9c41-6466caa12fca'; //测试服
// var token = "9add5b66-38e9-4784-ae44-8b9eb7afc897"; //班班
var localstore = new loaclstore()
// var isDevelpe = true;
var isDevelpe = false;


//推荐员工角色列表
export function recommendEmp() {
  return request({
      url: 'api-aicc/aicc-anon/recommendEmp',
      method: 'post',
  })
}

//添加员工
export function addEmp(opo) {
  return request({
      url: 'api-aicc/aicc/addEmp?access_token='+localstore.get("access_token"),
      method: 'post',
      data: opo,
  })
}

//获取员工详情
export function findEmpById(empId) {
  return request({
      url: 'api-aicc/aicc/findEmpById?empId='+empId + '&access_token='+localstore.get("access_token"),
      method: 'post',
     
  })
}

//推荐行业列表
export function industry() {
  return request({
      url: 'api-aicc/aicc-anon/industry',
      method: 'post',
  })
}

//上传prompt
export function updatePrompt(obj) {
  return request({
      url: 'api-aicc/aicc/updatePrompt?access_token='+localstore.get("access_token"),
    method: 'post',
    data: obj,
  })
}



//上传文件列表
export function empFileList(empId) {
  return request({
      url: 'api-aicc/aicc/empFileList?empId=' +empId + "&userId="+ localstore.get("hdUserId") +'&access_token='+localstore.get("access_token"),
    method: 'post',
   
  })
}
export function empFileListV2(empId) {
  return request({
      url: 'api-aicc/aicc/empFileListV2?empId=' +empId + "&userId="+ localstore.get("hdUserId") +'&access_token='+localstore.get("access_token"),
    method: 'post',
   
  })
}

export function fileStatusV1(fileId) {
  return request({
      url: 'api-aicc/aicc/fileStatusV1?fileId=' +fileId + '&access_token='+localstore.get("access_token"),
    method: 'post',
   
  })
}

//上传文件
export function knowledgeFile(obj) {
  return request({
      url: 'api-f/files/knowledgeFile',
    method: 'post',
    headers: {
      'Content-Type':'multipart/form-data;'
    },
    data: obj,
  })
}


export function knowledgeFileV1(obj) {
  return request({
      url: 'api-f/files/knowledgeFileV1',
    method: 'post',
    headers: {
      'Content-Type':'multipart/form-data;'
    },
    data: obj,
  })
}

//删除文件
export function deleteFile(id,id1) {
  return request({
      url: 'api-aicc/aicc/deleteFile?id='+id + "&empId=" + id1 + '&access_token='+localstore.get("access_token"),
    method: 'post',
    
  })
}
//白名单用户列表
export function rollList(id) {
  return request({
      url: 'api-aicc/aicc/rollList?orgId='+id+'&access_token='+localstore.get("access_token"),
      method: 'post',
    
  })
}
//删除白名单用户
export function deleteRoll(id) {
  return request({
      url: 'api-aicc/aicc/deleteRoll?id='+id+'&access_token='+localstore.get("access_token"),
      method: 'post',
    
  })
}
//添加白名单用户
export function addNumbers(obj) {
  return request({
      url: 'api-aicc/aicc/addNumbers?'+'access_token='+localstore.get("access_token"),
      method: 'post',
      data: obj,
    
  })
}


//查看全部qa
export function findQAList(obj) {
  return request({
      url: 'api-aicc/aicc/findQAList?page=1&length=9999&access_token='+localstore.get("access_token"),
      method: 'post',
      data: obj,
    
  })
}


//启用 禁用切片
export function updateQAStatus(obj) {
  return request({
      url: 'api-aicc/aicc/updateQAStatus?access_token='+localstore.get("access_token"),
    method: 'post',
    data: obj,
  })
}

//添加在线切片
export function addOrUpdateQA(obj) {
  return request({
      url: 'api-aicc/aicc/addOrUpdateQA?access_token='+localstore.get("access_token"),
    method: 'post',
    data: obj,
  })
}


export function knowledgeFileCV(obj) {
  return request({
      url: 'api-f/files/knowledgeFileCV1',
    method: 'post',
    headers: {
      'Content-Type':'multipart/form-data;'
    },
    data: obj,
  })
}
export function deleteQA(obj) {
  return request({
      url: 'api-aicc/aicc/deleteQA?access_token='+localstore.get("access_token"),
    method: 'post',
    data: obj,
  })
}

export function deleteQAV1 (obj) {
  return request({
      url: 'api-aicc/aicc/deleteQAV1?access_token='+localstore.get("access_token"),
    method: 'post',
    data: obj,
  })
}

export function CSVImageUpload(obj) {
  return request({
      url: 'api-f/files/CSVImageUploadV1',
    method: 'post',
    headers: {
      'Content-Type':'multipart/form-data'
    },
    data: obj,
  })
}

export function CSVImageUpload2(obj) {
  return request({
      url: 'api-f/files/files-anon/CSVImageUploadV1',
    method: 'post',
    headers: {
      'Content-Type':'multipart/form-data'
    },
    data: obj,
  })
}

export function fileUpload(obj) {
  return request({
      url: 'zuul/api-f/files/fileUpload',
    method: 'post',
    headers: {
      'Content-Type':'multipart/form-data'
    },
    data: obj,
  })
}


export function findQAListV2(id,fileId) {
  return request({
      url: 'api-aicc/aicc/findQAListV2?&access_token='+localstore.get("access_token") + '&empId='+id+'&fileId=' + fileId,
      method: 'post',
    
  })
}

export function isImport(fileId,status,empId) {
  return request({
      url: 'api-aicc/aicc/isImport?&access_token='+localstore.get("access_token") + '&fileId=' + fileId + "&status=" + status + "&empId=" + empId,
    method: 'post', 
  })
}


export function UpdateToAicc(empId) {
  return request({
      url: 'api-aicc/aicc/UpdateToAicc?&access_token='+localstore.get("access_token") + '&empId=' + empId ,
     method: 'post', 
  })
}


export function fileStatusV2 (fileId) {
  return request({
    url: 'api-aicc/aicc/fileStatusV2?empId=' + fileId + '&access_token=' + localstore.get("access_token"),
    method: 'post',
   
  })

}
export function QAupdateStatus (fileId) {
  return request({
    url: 'api-aicc/aicc/QAupdateStatus?empId=' + fileId + '&access_token=' + localstore.get("access_token"),
    method: 'post',
     
  })

}


export function exportCSV (empId) {
  return request({
    url: 'api-aicc/aicc/exportCSV?empId=' + empId + '&access_token=' + localstore.get("access_token"),
    method: 'post',
    responseType: 'blob',
     
  })
}


export function Material (fileId,type) {
  return request({
    url: 'api-aicc/aicc/Material?empId=' + fileId + "&type=" + type + '&access_token=' + localstore.get("access_token"),
    method: 'post',     
  })
}

export function findEmpByIdV2 (fileId) {
  return request({
    url: 'api-aicc/aicc/findEmpByIdV2?empId=' + fileId + '&access_token=' + localstore.get("access_token"),
    method: 'post',     
  })
}
//添加员工
export function updateHigherEmp(opo) {
  return request({
      url: 'api-aicc/aicc/updateHigherEmp?access_token='+localstore.get("access_token"),
      method: 'post',
      data: opo,
  })
}
export function updateCoreStatus(id1,id2,type) {
  return request({
      url: 'api-aicc/aicc/updateCoreStatus?access_token='+localstore.get("access_token") + "&id="+id1+"&empId="+id2 + "&type="+type,
      method: 'post',
     
  })
}



  //工作台员工数据
  export function receiveWorkbencheStaffListNet() {
    return request({
      url: 'api-aicc/aicc/empList?access_token='+localstore.get("access_token") + '&userId=' +localstore.get("hdUserId"),
      method: "post",
    });
}
export function recommendAnnoEmp() {
  return request({
    url: 'api-aicc/recruit-anon/recommendEmp',
    method: "post",
  });
}

export function recommendEmpList() {
  return request({
    url: 'api-aicc/recruit-anon/recommendEmpList?userId='+localstore.get("hdUserId"),
    method: "post",
  });
}  
export function shareEmp() {
  return request({
    url: 'api-aicc/recruit-anon/shareEmp',
    method: "post",
  });
} 

export function addShareEmpToAsk(id) {
  return request({
    url: 'api-aicc/recruit/addShareEmpToAsk?userId='+localstore.get("hdUserId")+'&access_token='+localstore.get("access_token")+"&shareEmpId="+id,
    method: "post",
  });
} 


export function empMarket() {
  return request({
    url: 'api-aicc/recruit/empMarket?access_token='+localstore.get("access_token"),
    method: "post",
  });
}  

export function empMarketAnon() {
  return request({
    url: 'api-aicc/recruit-anon/empMarket',
    method: "post",
  });
}  


export function addRecommendEmp(id) {
  return request({
    url: 'api-aicc/recruit/addRecommendEmp?userId='+localstore.get("hdUserId")+'&access_token='+localstore.get("access_token")+"&id="+id,
    method: "post",
  });
}  


